import icon1 from "../../images/icons/icon-1.png";
import icon2 from "../../images/icons/icon-2.png";
import icon3 from "../../images/icons/icon-3.png";

const Services = () => {
  return (
    <section className="services section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Services</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="item">
              <a href="architecture.html">
                {" "}
                <img src={icon1} alt="" />
                <h5>Architecture</h5>
                <div className="line"></div>
                {/* <p>
                  Architecture bibendum eros eget lacus the vulputate sit amet
                  vehicuta nibhen ulicera in the vitae miss.
                </p> */}
                <div className="numb">01</div>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <a href="interior-design.html">
                {" "}
                <img src={icon2} alt="" />
                <h5>Lifestyle</h5>
                <div className="line"></div>
                {/* <p>
                  Architecture bibendum eros eget lacus the vulputate sit amet
                  vehicuta nibhen ulicera in the vitae miss.
                </p> */}
                <div className="numb">02</div>
              </a>
            </div>
          </div>
          <div className="col-md-3">
            {/* <div className="item">
              <a href="urban-design.html">
                {" "}
                <img src={icon3} alt="" />
                <h5>Sourcing</h5>
                <div className="line"></div>
                <p>
                  Architecture bibendum eros eget lacus the vulputate sit amet
                  vehicuta nibhen ulicera in the vitae miss.
                </p>
                <div className="numb">03</div>
              </a>
            </div> */}
          </div>

          <div className="col-md-3">
            {/* <div className="item">
              <a href="urban-design.html">
                {" "}
                <img src={icon3} alt="" />
                <h5>Styling</h5>
                <div className="line"></div>
                <p>
                  Architecture bibendum eros eget lacus the vulputate sit amet
                  vehicuta nibhen ulicera in the vitae miss.
                </p>
                <div className="numb">03</div>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
