import { data } from "../../data";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section className="bauen-project section-padding2">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 mb-30 text-center animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Commercial</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["jakkur"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h5>JAKKUR COMPLEX</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/jakkur">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["apple"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>SINGAPORE</h6>
                <h5>APPLE FACADE RENOVATION</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/apple">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["bangalore-complex"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h5>BANGALORE COMPLEX</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/bangalore-complex">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["bagur"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h5>BAGUR COMPLEX</h5>
                <h6>RENAISSANCE HOLDINGS</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/bagur">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
