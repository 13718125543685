const Lines = () => {
  return (
    <section className="content-lines-wrapper">
      <div className="content-lines-inner">
        <div className="content-lines"></div>
      </div>
    </section>
  );
};

export default Lines;
