import projects1 from "../../images/projects/1.jpg";
import projects2 from "../../images/projects/2.jpg";
import projects3 from "../../images/projects/3.jpg";
import projects4 from "../../images/projects/4.jpg";

import bigdwelling from "../../images/S1.jpg";
import smalldwelling from "../../images/HH1.jpg";
import industrial from "../../images/O3.jpg"
import lifestyle from "../../images/BW1.jpg"
import commercial from "../../images/B1.jpg"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section className="projects section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Works</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel className='owl-theme' items={2} loop margin={10} nav>
              <div className="item">
                <div className="position-re o-hidden">
                  {" "}
                  <img src={bigdwelling} alt="" />{" "}
                </div>
                <div className="con">
                  {/* <h6>
                    <a href="cotton-house.html">Interior</a>
                  </h6> */}
                  <h5>
                    <Link to="/big-dwelling">
                    BIG DWELLING
                    </Link>
                  </h5>
                  <div className="line"></div>{" "}
                  {/* <a href="cotton-house.html">
                    <i className="ti-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  {" "}
                  <img src={smalldwelling} alt="" />{" "}
                </div>
                <div className="con">
                  {/* <h6>
                    <a href="armada-center.html">Exterior</a>
                  </h6> */}
                  <h5>
                  <Link to="/small-dwelling">
                    SMALL DWELLING
                    </Link>
                  </h5>
                  <div className="line"></div>{" "}
                  {/* <a href="armada-center.html">
                    <i className="ti-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  {" "}
                  <img src={industrial} alt="" />{" "}
                </div>
                <div className="con">
                  {/* <h6>
                    <a href="stonya-villa.html">Urban</a>
                  </h6> */}
                  <h5>
                  <Link to="/industrial">
                    INDUSTRIAL
                    </Link>
                  </h5>
                  <div className="line"></div>{" "}
                  {/* <a href="stonya-villa.html">
                    <i className="ti-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  {" "}
                  <img src={commercial} alt="" />{" "}
                </div>
                <div className="con">
                  {/* <h6>
                    <a href="prime-hotel.html">Interior</a>
                  </h6> */}
                  <h5>
                  <Link to="/commercial">
                    COMMERCIAL
                    </Link>
                  </h5>
                  <div className="line"></div>{" "}
                  {/* <a href="prime-hotel.html">
                    <i className="ti-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <div className="item">
                <div className="position-re o-hidden">
                  {" "}
                  <img src={lifestyle} alt="" />{" "}
                </div>
                <div className="con">
                  {/* <h6>
                    <a href="prime-hotel.html">Interior</a>
                  </h6> */}
                  <h5>
                  <Link to="/lifestyle">
                    LIFESTYLE
                    </Link>
                  </h5>
                  <div className="line"></div>{" "}
                  {/* <a href="prime-hotel.html">
                    <i className="ti-arrow-right"></i>
                  </a> */}
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
