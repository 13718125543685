import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";

const CommonNav = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="logo-wrapper valign">
      <div className="logo" style={{padding:"20px"}}>
          <NavLink to="/">
            {" "}
            <img src={logo} className="logo-img" alt="" />
          </NavLink>
        </div>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {" "}
        <span className="icon-bar">
          <i className="ti-line-double"></i>
        </span>{" "}
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item">
            <NavLink className="nav-link nav-color" to="/">
              Home
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink className="nav-link nav-color" to="/about">
              About
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            {" "}
            <span className="nav-link nav-color">
              {" "}
              WORKS <i className="ti-angle-down"></i>
            </span>
            <ul className="dropdown-menu last">
              <li className="dropdown-item">
                <NavLink to="/big-dwelling">BIG DWELLING</NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/small-dwelling">SMALL DWELLING</NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/industrial">INDUSTRIAL</NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/commercial">COMMERCIAL</NavLink>
              </li>
              <li className="dropdown-item">
                <NavLink to="/lifestyle">LIFESTYLE</NavLink>
              </li>
            </ul>
          </li>
          {/* <li className="nav-item dropdown"> <span className="nav-link nav-color"> Interior <i className="ti-angle-down"></i></span>
                        <ul className="dropdown-menu last">
                            <li className="dropdown-item"><NavLink to="/residential">Residential</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/retail">Retail</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/kitchen">Kitchen</NavLink></li>
                        </ul>
                    </li> */}
          <li className="nav-item">
            <NavLink className="nav-link nav-color" to="/connect">
              Connect
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default CommonNav;
