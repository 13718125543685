import { data } from "../../data";

const VideoComponent = (props) => {
    var name = props.name;
    
    var video = data.projects[name].videos[0];

    if(video) {
        return(
            <div className="card card-tall card-wide">
                <iframe width="560" height="315" src= { video } title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default VideoComponent;