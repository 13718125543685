import React, { useState } from "react";
import { data } from "../../data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronLeft, 
  faCircleChevronRight, 
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import "./wsp-gallery.css";
import VideoComponent from "./VideoComponent";

const Gallery = (props) => {
  const name = props.name;
  const info = data.projects[name].images;
  const video = data.projects[name].videos[0];

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    setSlideNumber((prevSlideNumber) =>
      prevSlideNumber === 0 ? info.length - 1 : prevSlideNumber - 1
    );
  };

  const nextSlide = () => {
    setSlideNumber((prevSlideNumber) =>
      prevSlideNumber === info.length - 1 ? 0 : prevSlideNumber + 1
    );
  };

  return (
	<>
	{!openModal && (
     <section className="section-padding2">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title2" style={{ color: "#808080" }}>
              {data.projects[name].name}
            </h2>
          </div>
        </div>
        <div className="image-mosaic">
          {info.map((img, index) => (
            <div className="card card-tall card-wide" key={index}>
                <img src={img} alt={img} onClick={() => {handleOpenModal(index)}} />
            </div>
          ))}
        </div>
        <br />
        <VideoComponent name={name} />
       </div>
	  </section>
	)}

      {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img src={info[slideNumber]} alt="" />
          </div>
        </div>
      )}
   </>
  );
};

export default Gallery;
