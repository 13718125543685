export const data = {
  projects: {
    poetree: {
      name: "POETREE",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Poetree/01.jpg",
        "../assets/img/Lifestyle/Poetree/02.jpg",
        "../assets/img/Lifestyle/Poetree/03.jpg",
        "../assets/img/Lifestyle/Poetree/04.jpg",
        "../assets/img/Lifestyle/Poetree/05.jpg",
        "../assets/img/Lifestyle/Poetree/06.jpg",
        "../assets/img/Lifestyle/Poetree/07.jpg",
        "../assets/img/Lifestyle/Poetree/08.jpg",
        "../assets/img/Lifestyle/Poetree/09.jpg",
        "../assets/img/Lifestyle/Poetree/10.jpg",
        "../assets/img/Lifestyle/Poetree/11.jpg",
        "../assets/img/Lifestyle/Poetree/12.jpg",
        "../assets/img/Lifestyle/Poetree/13.jpg",
        "../assets/img/Lifestyle/Poetree/14.jpg",
        "../assets/img/Lifestyle/Poetree/15.jpg",
        "../assets/img/Lifestyle/Poetree/16.jpg",
        "../assets/img/Lifestyle/Poetree/17.jpg",
        "../assets/img/Lifestyle/Poetree/18.jpg",
        "../assets/img/Lifestyle/Poetree/19.png",
        "../assets/img/Lifestyle/Poetree/20.jpg",
        "../assets/img/Lifestyle/Poetree/21.jpg",
        "../assets/img/Lifestyle/Poetree/22.jpg",
        "../assets/img/Lifestyle/Poetree/24.jpg",
        "../assets/img/Lifestyle/Poetree/25.jpg",
        "../assets/img/Lifestyle/Poetree/26.jpg",
        "../assets/img/Lifestyle/Poetree/27.jpg",
        "../assets/img/Lifestyle/Poetree/28.png",
      ],
      videos: [],
    },
    troika: {
      name: "Troika",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Troika/T01.jpg",
        "../assets/img/Lifestyle/Troika/T02.jpg",
        "../assets/img/Lifestyle/Troika/T03.jpg",
        "../assets/img/Lifestyle/Troika/T04.jpg",
        "../assets/img/Lifestyle/Troika/T05.jpg",
        "../assets/img/Lifestyle/Troika/T06.jpg",
        "../assets/img/Lifestyle/Troika/T07.jpg",
        "../assets/img/Lifestyle/Troika/T08.jpg",
        "../assets/img/Lifestyle/Troika/T09.jpg",
        "../assets/img/Lifestyle/Troika/T10.jpg",
        "../assets/img/Lifestyle/Troika/T11.jpg",
        "../assets/img/Lifestyle/Troika/T14.jpg",
        "../assets/img/Lifestyle/Troika/T15.jpg",
        "../assets/img/Lifestyle/Troika/T16.jpg",
        "../assets/img/Lifestyle/Troika/T17.jpg",
        "../assets/img/Lifestyle/Troika/T18.jpg",
        "../assets/img/Lifestyle/Troika/T19.jpg",
        "../assets/img/Lifestyle/Troika/T20.jpg",
        "../assets/img/Lifestyle/Troika/T22.jpg",
        "../assets/img/Lifestyle/Troika/T23.jpg",
      ],
      videos: ["https://www.youtube.com/embed/keDwLyIZ6ow"],
    },
    clovelly: {
      name: "Clovelly",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Clovelly/SC -1.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -2.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -3.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -4.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -5.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -6.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -7.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -8.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -9.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -10.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -11.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -12.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -13.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -14.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -15.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -16.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -17.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -18.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -19.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -20.jpg",
        "../assets/img/Lifestyle/Clovelly/SC -21.jpg"
      ],
      videos: [],
    },
    "carlton-creek": {
      name: "Carlton Creek",
      back: "/lifestyle",
      images: ["../assets/img/Lifestyle/Clovelly/grey.jpg"],
      videos: [],
    },
    adukale: {
      name: "Adukale Experience Center",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Adukale/Slide1.jpg",
        "../assets/img/Lifestyle/Adukale/Slide2.jpg",
        "../assets/img/Lifestyle/Adukale/Slide3.jpg",
        "../assets/img/Lifestyle/Adukale/Slide4.jpg",
        "../assets/img/Lifestyle/Adukale/Slide5.jpg",
      ],
      videos: [],
    },
    "bangalore-one": {
      name: "Bangalore One",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Bangalore One/BW1.jpg",
        "../assets/img/Lifestyle/Bangalore One/BW2.jpg",
      ],
      videos: [],
    },
    oaktree: {
      name: "Oaktree Kitchens",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Oaktree/Slide1.jpg",
        "../assets/img/Lifestyle/Oaktree/Slide2.jpg",
        "../assets/img/Lifestyle/Oaktree/Slide3.jpg",
        "../assets/img/Lifestyle/Oaktree/Slide4.jpg",
        "../assets/img/Lifestyle/Oaktree/Slide5.jpg",
      ],
      videos: [],
    },
    sukhtaraa: {
      name: "Sukhtaraa",
      back: "/lifestyle",
      images: [
        "../assets/img/Lifestyle/Sukhtaraa/01.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/02.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/04.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/05.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/06.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/07.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/08.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/09.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/10.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/11.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/12.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/13.jpg",
        "../assets/img/Lifestyle/Sukhtaraa/14.jpg",
      ],
      videos: ["https://www.youtube.com/embed/cfjS2jp3Goo"],
    },
    bellevue: {
      name: "Bellevue",
      back: "http://localhost:3000/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Bellevue/B1.jpg",
        "../assets/img/Big Dwelling/Bellevue/B2.jpg",
        "../assets/img/Big Dwelling/Bellevue/B3.jpg",
        "../assets/img/Big Dwelling/Bellevue/B4.jpg",
        "../assets/img/Big Dwelling/Bellevue/B5.jpg",
      ],
      videos: [],
    },
    shanks1: {
      name: "Shanks I",
      back: "/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Shanks 1/S1.jpg",
        "../assets/img/Big Dwelling/Shanks 1/S2.jpg",
        "../assets/img/Big Dwelling/Shanks 1/S3.jpg",
      ],
      videos: [],
    },
    shanks2: {
      name: "Shanks II",
      back: "/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Shanks 2/S1.jpg",
        "../assets/img/Big Dwelling/Shanks 2/S2.jpg",
        "../assets/img/Big Dwelling/Shanks 2/S3.jpg",
        "../assets/img/Big Dwelling/Shanks 2/S4.jpg",
      ],
      videos: [],
    },
    "shanks-sunnyside": {
      name: "Shanks Sunnyside",
      back: "/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Shanks Sunnyside/SS1.jpg",
        "../assets/img/Big Dwelling/Shanks Sunnyside/SS2.jpg",
        "../assets/img/Big Dwelling/Shanks Sunnyside/SS3.jpg",
        "../assets/img/Big Dwelling/Shanks Sunnyside/SS4.jpg",
      ],
      videos: [],
    },
    "sridhar-reddy": {
      name: "Apartment for Mr. Sridhar Reddy",
      back: "/big-dwelling",
      images: ["../assets/img/Big Dwelling/Sridhar Reddy/SR1.jpg"],
      videos: [],
    },
    residences: {
      name: "Residences",
      back: "/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Residences/Slide1.jpg",
        "../assets/img/Big Dwelling/Residences/Slide2.jpg",
        "../assets/img/Big Dwelling/Residences/Slide3.jpg",
      ],
      videos: [],
    },
    "trees-birds": {
      name: "Trees and Birds",
      back: "/big-dwelling",
      images: [
        "../assets/img/Big Dwelling/Birds and Trees/B1.jpg",
        "../assets/img/Big Dwelling/Birds and Trees/B2.jpg",
        "../assets/img/Big Dwelling/Birds and Trees/B3.jpg",
        "../assets/img/Big Dwelling/Birds and Trees/B4.jpg",
      ],
      videos: [],
    },
    "hassan-house": {
      name: "Hassan House",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Hassan House/HH1.jpg",
        "../assets/img/Small Dwelling/Hassan House/HH2.jpg",
        "../assets/img/Small Dwelling/Hassan House/HH3.jpg",
      ],
      videos: [],
    },
    lumbini: {
      name: "Lumbini Farmhouse",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Lumbini/L1.jpg",
        "../assets/img/Small Dwelling/Lumbini/L2.jpg",
        "../assets/img/Small Dwelling/Lumbini/L3.jpg",
        "../assets/img/Small Dwelling/Lumbini/L4.jpg",
      ],
      videos: [],
    },
    "prashant-row-housing": {
      name: "Prashant Row Housing",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Prashant/P1.jpg",
        "../assets/img/Small Dwelling/Prashant/P2.jpg",
        "../assets/img/Small Dwelling/Prashant/P3.jpg",
        "../assets/img/Small Dwelling/Prashant/P4.jpg",
        "../assets/img/Small Dwelling/Prashant/P5.jpg",
        "../assets/img/Small Dwelling/Prashant/P6.jpg",
        "../assets/img/Small Dwelling/Prashant/P7.jpg",
      ],
      videos: [],
    },
    "purushottam-house": {
      name: "Purushottam House",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Purushottam/P1.jpg",
        "../assets/img/Small Dwelling/Purushottam/P2.jpg",
        "../assets/img/Small Dwelling/Purushottam/P3.jpg",
      ],
      videos: [],
    },
    "sahu-house": {
      name: "Sahu House",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Sahu/S1.jpg",
        "../assets/img/Small Dwelling/Sahu/S2.jpg",
        "../assets/img/Small Dwelling/Sahu/S3.jpg",
        "../assets/img/Small Dwelling/Sahu/S4.jpg",
        "../assets/img/Small Dwelling/Sahu/S5.jpg",
      ],
      videos: [],
    },
    vijayanagara: {
      name: "Vijayanagara House",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Vijayanagara/V1.jpg",
        "../assets/img/Small Dwelling/Vijayanagara/V2.jpg",
        "../assets/img/Small Dwelling/Vijayanagara/V3.jpg",
        "../assets/img/Small Dwelling/Vijayanagara/V4.jpg",
      ],
      videos: [],
    },
    "villa-prabhu": {
      name: "Villa Prabhu",
      back: "/small-dwelling",
      images: [
        "../assets/img/Small Dwelling/Villa Prabhu/Slide1.jpg",
        "../assets/img/Small Dwelling/Villa Prabhu/Slide2.jpg",
        "../assets/img/Small Dwelling/Villa Prabhu/Slide3.jpg",
      ],
      videos: [],
    },
    goel: {
      name: "Goel Factory",
      back: "/industrial",
      images: [
        "../assets/img/Industrial/Goel/G2.jpg",
        "../assets/img/Industrial/Goel/G3.jpg",
        "../assets/img/Industrial/Goel/G4.jpg",
      ],
      videos: [],
    },
    "oaktree-factory": {
      name: "Oaktree Factory",
      back: "/industrial",
      images: [
        "../assets/img/Industrial/Oaktree-factory/O1.jpg",
        "../assets/img/Industrial/Oaktree-factory/O2.jpg",
        "../assets/img/Industrial/Oaktree-factory/O3.jpg",
        "../assets/img/Industrial/Oaktree-factory/O4.jpg",
        "../assets/img/Industrial/Oaktree-factory/O5.jpg",
        "../assets/img/Industrial/Oaktree-factory/O6.jpg",
        "../assets/img/Industrial/Oaktree-factory/O7.jpg",
        "../assets/img/Industrial/Oaktree-factory/O8.jpg",
        "../assets/img/Industrial/Oaktree-factory/O9.jpg",
        "../assets/img/Industrial/Oaktree-factory/O10.jpg",
      ],
      videos: [],
    },
    apple: {
      name: "Apple Facade Rennovation",
      back: "/commercial",
      images: [
        "../assets/img/Commercial/Apple/A1.jpg",
        "../assets/img/Commercial/Apple/A2.jpg",
        "../assets/img/Commercial/Apple/A3.jpg",
      ],
      videos: [],
    },
    bagur: {
      name: "Bagur Complex",
      back: "/commercial",
      images: [
        "../assets/img/Commercial/Bagur/B1.jpg",
        "../assets/img/Commercial/Bagur/B2.jpg",
        "../assets/img/Commercial/Bagur/B3.jpg",
        "../assets/img/Commercial/Bagur/B4.jpg",
      ],
      videos: [],
    },
    "bangalore-complex": {
      name: "Bangalore Complex",
      back: "/commercial",
      images: [
        "../assets/img/Commercial/Bangalore-complex/B1.jpg",
        "../assets/img/Commercial/Bangalore-complex/B2.jpg",
        "../assets/img/Commercial/Bangalore-complex/B3.jpg",
        "../assets/img/Commercial/Bangalore-complex/B4.jpg",
      ],
      videos: [],
    },
    jakkur: {
      name: "Jakkur Complex",
      back: "/commercial",
      images: [
        "../assets/img/Commercial/Jakkur/J1.jpg",
        "../assets/img/Commercial/Jakkur/J2.jpg",
        "../assets/img/Commercial/Jakkur/J3.jpg",
        "../assets/img/Commercial/Jakkur/J4.jpg",
        "../assets/img/Commercial/Jakkur/J5.jpg",
        "../assets/img/Commercial/Jakkur/J6.jpg",
      ],
      videos: [],
    },
  },
  profile: [
    "../assets/img/Profile/1.jpg",
    "../assets/img/Profile/2.jpg",
    "../assets/img/Profile/3.jpg",
    "../assets/img/Profile/4.jpg",
    "../assets/img/Profile/5.jpg",
  ],
  "profile-mob": [
    "../assets/img/Profile-mob/1.jpg",
    "../assets/img/Profile-mob/2.jpg",
    "../assets/img/Profile-mob/3.jpg",
    "../assets/img/Profile-mob/5.jpg",
    "../assets/img/Profile-mob/6.jpg",
    "../assets/img/Profile-mob/7.jpg",
    "../assets/img/Profile-mob/8.jpg",
    "../assets/img/Profile-mob/9.jpg",
    "../assets/img/Profile-mob/10.jpg",
  ],
};
