// common components!
import Preloader from "../common/Preloader";
import Lines from "../common/Lines";
import Footer from "../common/Footer";
import CommonNav from "../common/CommonNav";

// sub components!
import HeaderBanner from "./sub-components/HeaderBanner";
import Projects from "./sub-components/Projects";

const IndustrialComponent = () => {
    return(
        <>
            {/* <Preloader/> */}
            <CommonNav/>
            <div className="content-wrapper">
                <Lines/>
                <HeaderBanner/>
                <Projects/>
                <Footer/>
            </div>
        </>
    )
}

export default IndustrialComponent;