import about from "../../images/about.jpg";

const About = () => {
  return (
    <section className="about section-padding">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 mb-30 animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style={{ color: "#808080" }}>Slate</span>
            </h2>
            <p>Utkalika Priyadarsini has been practicing as an architect and interior designer since 2002. </p>
            <p>Having worked as on a variety of design scales and projects(ranging from small residences to big institutional campuses ) and some very prestigious firms for 15 years it was time to probe for more.</p>

            <p>With intent to explore further in a more versatile and responsible manner and to give further vision to her passion for design, Utkalika founded  S L A T E - an Architectural and Lifestyle Design Studio in 2016</p>

            <p>S L A T E  is a multidisciplinary design practice, which allows for further exploration and experimentation in all forms of design.</p>

            <p>S L A T E  as the name suggests works on a premise that allows new ideas to be written every time, suggesting new beginnings with every project. It nurtures the idea of a project as a continuously evolving process, to better lifestyles, adding value to the end user irrespective of its scale.</p>

            <p>S L A T E  is always looking at pragmatic solutions to create living environments and life experiences, be it in the design of a singular object as in product design or a vast campus with multiple buildings.</p>

            <p>S L A T E  believes in Responsible Responsive Design.</p>
          </div>
          {/* <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
            <div className="about-img">
              <div className="img">
                {" "}
                <img src={about} className="img-fluid" alt="" />{" "}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
