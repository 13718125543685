import React from "react";
import { useParams } from "react-router-dom";

import CommonNav from "../common/CommonNav";
import Footer from "../common/Footer";
import Lines from "../common/Lines";
import Preloader from "../common/Preloader";
import Gallery from "./sub-components/Gallery";
import HeaderBanner from "./sub-components/HeaderBanner";
import NavGrid from "./sub-components/NavGrid";

const GalleryComponent = () => {
    let { name } = useParams();

    return(
        <>
            {/* <Preloader/> */}
            <CommonNav/>
            <div className="content-wrapper">
            <Lines/>
                <HeaderBanner/>
                <Gallery name = {name}/>
                <NavGrid name = {name}/>
                <Footer/>
            </div>
        </>
    )
}

export default GalleryComponent;