// Common components
import Navbar from '../common/Navbar';
import Preloader from '../common/Preloader';
import Footer from '../common/Footer';
import Lines from '../common/Lines';


// sub components!
import Slider from './sub-components/Slider';
import About from './sub-components/About';
import Projects from './sub-components/Projects';
import Services from './sub-components/Services';

const HomeComponent = () => {
    return(
        <>  
            {/* <Preloader/> */}
            <Navbar/>
            <Slider/>
            {/* Content */}
            <div className= "content-wrapper">
                {/* Lines */}
                <Lines/>
                <About/>
                <Projects/>
                <Services/>
                <Footer/>
            </div>
        </>
    )
}

export default HomeComponent;