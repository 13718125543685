import { data } from "../../data";
import slider1 from "../../images/slider/1.jpg";
import slider2 from "../../images/slider/2.jpg";
import slider3 from "../../images/slider/3.jpg";
import slider4 from "../../images/slider/4.jpg";

import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section className="bauen-project section-padding2">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 mb-30 text-center animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style={{color: "#808080"}}>Big Dwelling</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data["projects"]["trees-birds"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h4>TREES & BIRDS</h4>
                <h6>JAIN LIFE SPACES</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/trees-birds">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["bellevue"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h4>BELLEVUE</h4>
                <h6>RENAISSANCE HOLDINGS</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/bellevue">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["shanks1"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h4>SHANKS I</h4>
                <h6>RENAISSANCE HOLDINGS</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/shanks1">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["shanks2"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h4>SHANKS II</h4>
                <h6>RENAISSANCE HOLDINGS</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/shanks2">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["shanks-sunnyside"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h4>SHANKS SUNNYSIDE</h4>
                <h6>RENAISSANCE HOLDINGS</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/shanks-sunnyside">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["sridhar-reddy"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>APARTMENT FOR</h6>
                <h4>MR.SRIDHAR REDDY</h4>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/sridhar-reddy">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["residences"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h4>RESIDENCES</h4>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/residences">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
