const HeaderBanner = () => {
  return (
    <section
      className="banner-header banner-img valign bg-img bg-fixed"
      data-overlay-light="3"
    >
      <div className="left-panel"></div>
    </section>
  );
};

export default HeaderBanner;
