const Contact = () => {
  console.log("Contact rendered");
  return (
    <section className="section-padding2">
      <div className="container">
        <div className="row">
          <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Connect</span>
            </h2>{" "}
          </div>
        </div>
        <div className="row mb-90">
          {/* <div
            className="col-md-4 mb-30 animate-box"
            data-animate-effect="fadeInUp"
          >
            <p>
              <b>Bauen Architecture Firm</b>
            </p>
            <p>
              Our firm nisl sodales sit amet sapien idea placerat sodales
              orcite. Vivamus ne miss rhoncus felis bauen architecture.
            </p>
            <p>
              <b>VAT :</b> USA002323065B06
            </p>
          </div> */}
          <div
            className="col-md-6 mb-30 animate-box"
            data-animate-effect="fadeInUp"
          >
            <p>
              <b className="projects4 caption h4" style={{fontSize:"25px", fontFamily: "Oswald, sans-serif", fontWeight: "400", lineHeight: "1.25em"}}>C A L L : <a href="tel:+919886161120">+91&nbsp;&nbsp; 9 8 8 6 1 6 1 1 2 0</a></b> 
            </p>
            <p>
              <b className="projects4 caption h4" style={{fontSize:"25px", fontFamily: "Oswald, sans-serif", fontWeight: "400", lineHeight: "1.25em"}}>E M A I L : <a href="mailto:utkalika@slateworks.net">utkalika@slateworks.net</a></b> 
            </p>
            <p>
            <b className="projects4 caption h4" style={{fontSize:"25px", fontFamily: "Oswald, sans-serif", fontWeight: "400", lineHeight: "1.25em"}}>F O L L O W  &nbsp;&nbsp;U S :
                &nbsp;&nbsp;&nbsp;
                <a href="https://www.facebook.com/slateworks1978">
                  <i className="ti-facebook"></i>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://www.instagram.com/slate.works/">
                  <i className="ti-instagram"></i>
                </a>
              </b> 
            </p>
            {/* <div className="sub-footer">
              <div className="container">
              <p>
                <b>Follow us at:</b>
              </p>
                  <div className="abot">
                    <div className="social-icon">
                      {" "}
                      <a href="https://www.facebook.com/slateworks1978">
                        <i className="ti-facebook"> @slateworks1978</i>
                      </a><br/>
                      <a href="https://www.instagram.com/slate.works/">
                        <i className="ti-instagram"> @slate.works</i>
                      </a>
                    </div>
                  </div>
                
              </div>
            </div> */}
            {/* <p>
              <b>Address :</b> 24 King St, Charleston, 29401 USA
            </p> */}
          </div>
          {/* <div className="col-md-4 animate-box" data-animate-effect="fadeInUp">
            <p>
              <b>Contact Form</b>
            </p>
            <form method="post" className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full Name"
                />{" "}
              </div>
              <div className="col-md-12">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required=""
                />{" "}
              </div>
              <div className="col-md-12">
                <textarea
                  name="message"
                  id="message"
                  cols="40"
                  rows="4"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="col-md-12">
                <button className="butn-dark mt-15">
                  <a href="#0">
                    <span>Send</span>
                  </a>
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
