const Footer = () => {
  return (
    <footer className="main-footer dark">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Call</h6>
              </div>
              <p>
                <a href="tel:+919886161120">+91 9886161120</a>
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Email</h6>
              </div>
              <p>
                <a href="mailto:utkalika@slateworks.net">
                  utkalika@slateworks.net
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Follow Us</h6>
              </div>
              <div className="social-icon">
                {" "}
                <a href="https://www.facebook.com/slateworks1978">
                  <i className="ti-facebook"></i>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://www.instagram.com/slate.works/">
                  <i className="ti-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="text-left">
                <p>© 2022 Slate. All right reserved.</p>
              </div>
            </div>
            <div className="col-md-4 abot">
              {/* <div className="social-icon">
                {" "}
                <a href="https://www.facebook.com/slateworks1978">
                  <i className="ti-facebook"></i>
                </a>
                <a href="https://www.instagram.com/slate.works/">
                  <i className="ti-instagram"></i>
                </a>
              </div> */}
            </div>
            <div className="col-md-4">
              <p className="right">
                <a href="#">Terms &amp; Conditions</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
