import React from 'react';
import logo from "../images/logo.png";
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return(
        <nav className="navbar navbar-expand-lg">
            <div className="logo-wrapper valign">
                <div className="logo" style={{padding:"20px"}}>
                    <NavLink to="/"> <img src= { logo } className="logo-img" alt=""/>
                        {/* <h2><span>ARCHITECTURE | LIFESTYLE | SOURCING | STYLING</span></h2> */}
                    </NavLink>
                </div>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="icon-bar"><i className="ti-line-double"></i></span> </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item"><a className="nav-link active" href = "/">Home</a></li> */}
                    <li className="nav-item"><NavLink className="nav-link" to = "/about">About</NavLink></li>
                    <li className="nav-item dropdown"> <span className="nav-link"> Works <i className="ti-angle-down"></i></span>
                        <ul className="dropdown-menu last">
                            <li className="dropdown-item"><NavLink to="/big-dwelling">BIG DWELLING</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/small-dwelling">SMALL DWELLING</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/industrial">INDUSTRIAL</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/commercial">COMMERCIAL</NavLink></li>
                            <li className="dropdown-item"><NavLink to="/lifestyle">LIFESTYLE</NavLink></li>
                        </ul>
                    </li>
                    {/* <li className="nav-item dropdown"> <span className="nav-link"> Interior <i className="ti-angle-down"></i></span>
                        <ul className="dropdown-menu last">
                            <li className="dropdown-item"><a href="/residential">Residential</a></li>
                            <li className="dropdown-item"><a href="/retail">Retail</a></li>
                            <li className="dropdown-item"><a href="/kitchen">Kitchen</a></li>
                        </ul>
                    </li> */}
                    <li className="nav-item"><NavLink className="nav-link" to = "/connect">Connect</NavLink></li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;