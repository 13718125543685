import { data } from "../../data";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section className="bauen-project section-padding2">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 mb-30 text-center animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style={{ color: "#808080" }}>Industrial</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["oaktree-factory"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Interior</h6> */}
                <h6>HAROHALLI</h6>
                <h5>OAKTREE FACTORY</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/oaktree-factory">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["goel"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>CHENNAI</h6>
                <h5>GOEL FACTORY</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/goel">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
