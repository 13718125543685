import slider1 from "../../images/slider/1.jpg";
import { data } from "../../data";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

const { height, width } = getWindowDimensions();

const SliderImages = () => {
    if(width <= 480) {
        return(
            data["profile-mob"].map(prof => {
                return(
                    <div
                      className="text-right item bg-img"
                      data-overlay-dark="3"
                      style = {{ backgroundImage: `url(${prof})` }}
                    >
                      <div className="v-middle caption mt-30">
                        <div className="container">
                          <div className="row">
                          </div>
                        </div>
                      </div>
                    </div>
                )
            })
        )
    } else {
        return(
            data.profile.map(prof => {
                return(
                    <div
                      className="text-right item bg-img"
                      data-overlay-dark="3"
                      style = {{ backgroundImage: `url(${prof})` }}
                    >
                      <div className="v-middle caption mt-30">
                        <div className="container">
                          <div className="row">
                          </div>
                        </div>
                      </div>
                    </div>
                )
            })
        )
    }
}

export default SliderImages;

