import React from "react";
import { Link } from "react-router-dom";
import { data } from "../../data";

const NavGrid = (props) => {
	var name = props.name;
    return(
        <section className="projects-prev-next">
			<div className="container">
				<div className="row">
                    <div className="col-md-6">

                    </div>
					<div className="col-md-6">
						<div className="d-sm-flex align-items-center justify-content-between">
							<Link to = { data.projects[name].back }><i className="ti-layout-grid3-alt"></i></Link>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default NavGrid;