import slider1 from "../../images/slider/1.jpg";
import slider2 from "../../images/slider/2.jpg";
import slider3 from "../../images/slider/3.jpg";
import slider4 from "../../images/slider/4.jpg";

import { Link } from "react-router-dom";
import { data } from "../../data";

const Projects = () => {
  return (
    <section className="bauen-project section-padding2">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 mb-30 text-center animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Small Dwelling</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["hassan-house"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Interior</h6> */}
                <h5>HASSAN HOUSE</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/hassan-house">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["prashant-row-housing"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h5>PRASHANT ROW HOUSING</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/prashant-row-housing">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["purushottam-house"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Urban</h6> */}
                <h5>PURUSHOTTAM HOUSE</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/purushottam-house">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["lumbini"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h6>NANDI HILLS</h6>
                <h5>LUMBINI FARMHOUSE</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/lumbini">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["sahu-house"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BHUBANESWAR</h6>
                <h5>SAHU HOUSE</h5>
{/*                 
                <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                 <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/sahu-house">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["vijayanagara"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h5>VIJAYANAGARA HOUSE</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                 <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/vijayanagara">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data["projects"]["villa-prabhu"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Urban</h6> */}
                <h5>VILLA PRABHU</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/villa-prabhu">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
