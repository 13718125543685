// common components!
import Footer from "../common/Footer";
import Lines from "../common/Lines";
import CommonNav from "../common/CommonNav";

// sub components!
// import Team from "./sub-components/Team";
import About from "./sub-components/About";


const AboutComponent = () => {
    return(
        <>  
            <CommonNav/>
            <div className="content-wrapper">
                <Lines/>
                <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-light="3">
                    <div className="left-panel"></div>
                </section>
                <About/>
                {/* <Team/> */}
                <Footer/>
            </div>
        </> 
    )
}

export default AboutComponent;