import slider1 from "../../images/slider/1.jpg";
import slider2 from "../../images/slider/2.jpg";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { data } from "../../data";
import SliderImages from "./SliderImages";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Slider = () => {
  const { height, width } = getWindowDimensions();

  return (
    <header className="header slider-fade">
      <OwlCarousel className='owl-theme' items={1} loop margin={10} nav>
        <SliderImages/>
        {/* <div
          className="text-right item bg-img"
          data-overlay-dark="4"
          data-background={slider2}
        >
          <div className="v-middle caption mt-30">
            <div className="container">
              <div className="row">
              </div>
            </div>
          </div>
        </div> */}
      </OwlCarousel>
      <div className="hero-corner"></div>
      <div className="hero-corner3"></div>
      <div className="left-panel">
        <ul className="social-left clearfix">
          <li>
            <a href="https://www.instagram.com/slate.works/">
              <i className="ti-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/slateworks1978">
              <i className="ti-facebook"></i>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Slider;
