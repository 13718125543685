import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeComponent from "./components/HomeComponent/HomeComponent";
import AboutComponent from "./components/AboutComponent/AboutComponent";
import ContactComponent from "./components/ContactComponent/ContactComponent";
import BigDwellingComponent from "./components/BigDwellingComponent/BigDwellingComponent";
import SmallDwellingComponent from "./components/SmallDwellingComponent/SmallDwellingComponent";
import IndustrialComponent from "./components/IndustrialComponent/IndustrialComponent";
import CommercialComponent from "./components/CommercialComponent/CommercialComponent";
import InteriorComponent from "./components/InteriorComponent/InteriorComponent";
import GalleryComponent from "./components/GalleryComponent/GalleryComponent";

function App() {
  return (
    <Router>
      <div className="App">
          <Switch>
            <Route exact path = "/">
              <HomeComponent/>
            </Route>
            <Route path = "/about">
              <AboutComponent/>
            </Route>
            <Route path = "/connect">
              <ContactComponent/>
            </Route>
            <Route path = "/big-dwelling">
              <BigDwellingComponent/>
            </Route>
            <Route path = "/small-dwelling">
              <SmallDwellingComponent/>
            </Route>
            <Route path = "/industrial">
              <IndustrialComponent/>
            </Route>
            <Route path = "/commercial">
              <CommercialComponent/>
            </Route>
            <Route path = "/lifestyle">
              <InteriorComponent/>
            </Route>
            <Route path = "/project/:name">
              <GalleryComponent/>
            </Route>
          </Switch>      
      </div>
    </Router>
  );
}

export default App;
