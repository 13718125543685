// common components!
import Preloader from "../common/Preloader";
import Footer from "../common/Footer";
import Lines from "../common/Lines";
import CommonNav from "../common/CommonNav";

// import sub components!
import Contact from "./sub-components/Contact";
import HeaderBanner from "./sub-components/HeaderBanner";

const ContactComponent = () => {
    return(
        <>
            {/* <Preloader/> */}
            <CommonNav/>
            <div className="content-wrapper">
                <Lines/>
                <HeaderBanner/>
                <Contact/>
                {/* <Footer/> */}
            </div>
        </>
    )
}

export default ContactComponent;