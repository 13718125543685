import { Link } from "react-router-dom";
import slider1 from "../../images/slider/1.jpg";
import slider2 from "../../images/slider/2.jpg";
import slider3 from "../../images/slider/3.jpg";

import adukale from "../../images/Lifestyle/Adukale/Slide1.jpg";
import oaktree from "../../images/Lifestyle/Oaktree/Slide1.jpg";
import sukhtaraa from "../../images/Lifestyle/Sukhtaraa/01.jpg";
import { data } from "../../data";

const Projects = () => {
  return (
    <section className="bauen-project section-padding2">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 mb-30 text-center animate-box"
            data-animate-effect="fadeInUp"
          >
            <h2 className="section-title">
              <span style = {{ color: "#808080" }}>Lifestyle</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["clovelly"].images[1]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>BANGALORE</h6>
                <h5>CLOVELLY</h5>
                <h6>SHOBHA</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/clovelly">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["troika"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h5>TROIKA</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/troika">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["poetree"].images[15]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Urban</h6> */}
                <h5>POETREE</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/poetree">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={sukhtaraa} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h5>SUKHTARAA</h5>
                <h6>ELITA PROMENADE</h6>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/sukhtaraa">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>

            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={adukale} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>GANDHI BAZAAR</h6>
                <h5>ADUKALE EXPERIENCE CENTER</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="cotton-house.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/adukale">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["carlton-creek"].images[0]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                <h6>HYDERABAD</h6>
                <h5>CARLTON CREEK</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="armada-center.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/carlton-creek">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={ data["projects"]["bangalore-one"].images[0] } alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Urban</h6> */}
                <h5>ONE BANGALORE WEST</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="stonya-villa.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/bangalore-one">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div
              className="projects4 left animate-box"
              data-animate-effect="fadeInUp"
            >
              <figure>
                <img src={data.projects["oaktree"].images[4]} alt="" className="img-fluid" />
              </figure>
              <div className="caption">
                {/* <h6>Exterior</h6> */}
                <h5>OAKTREE KITCHENS</h5>
                {/* <p>
                  Architecture viverra tristique duis vitae diam neque nivamus
                  aestan ateuene artines finibus. Nedana setlie suscipe the
                  fermen.
                </p>
                <div className="butn-dark mt-15">
                  {" "}
                  <a href="prime-hotel.html">
                    <span>Discover</span>
                  </a>{" "}
                </div> */}
                <div className="butn-dark mt-15">
                  {" "}
                  <Link to = "/project/oaktree">
                    <span>View more</span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
